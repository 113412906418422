/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    collection: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 3.5a.5.5 0 010-1h11a.5.5 0 010 1zm2-2a.5.5 0 010-1h7a.5.5 0 010 1zM0 13a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0016 13V6a1.5 1.5 0 00-1.5-1.5h-13A1.5 1.5 0 000 6zm1.5.5A.5.5 0 011 13V6a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7a.5.5 0 01-.5.5z"/>',
    },
});
